import React from 'react';
import './index.less';
const ModalComponent = Loader.loadBaseComponent('ModalComponent');
const Similar = Loader.loadBaseComponent('Card', 'Similar');
const NoData = Loader.loadBaseComponent('NoData');

class SimilarModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      choseList: [],
      SimilarList: [],
      personId: undefined
    };
  }

  componentDidMount() {
    if(this.props.personData) {
      this.querySimilarVids(this.props.personData.personId || this.props.personData.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.personData !== this.props.personData) {
      this.querySimilarVids(nextProps.personData.personId||nextProps.personData.id);
    }
  }

  querySimilarVids = personId => {
    if(!personId){
      return
    }
    Service.person.querySimilarVids({ personId }).then(res => {
      let data = res.data || [];
      let choseList = [];
      data.forEach(v => {
        if(v.type === -1) {
          v.checked = false;
        } else {
          v.checked = true;
          choseList.push({
            aid: v.aid,
            similarity: v.score,
            aidUrl: v.newestPictureUrl || v.lastestPictureUrl,
            type: 1,
          });
        }
      });
      this.setState({
        SimilarList: data,
        choseList,
        personId
      });
    });
  };

  onClick = (type, data) => {
    let { SimilarList } = this.state;
    let findSimlar = SimilarList.findIndex(v => v.aid === data.aid);
    if (findSimlar > -1) {
      SimilarList[findSimlar].checked = type;
    }
    let aids = [];
    SimilarList.map(v => {
      if (v.checked === true) {
        aids.push({ 
          aid: v.aid,
          aidUrl: v.newestPictureUrl || v.lastestPictureUrl,
          similarity: v.score,
          type: 1,
        });
      }
    });
    this.setState({
      choseList: aids,
      SimilarList
    });
  };
  //设置关联
  onOk = () => {
    let { choseList,SimilarList } = this.state;
    const { personData,onCancel } = this.props;
    if(!SimilarList.length){
      return
    }
    if (choseList.length === 0) {
      choseList = null
    }
    let option = {
      personId: personData.personId || personData.id ,
      aidBindParams: choseList
    };
    Service.person.addRelationVids(option).then(() => {
      onCancel && onCancel()
      SocketEmitter.emit(SocketEmitter.eventName.updatePerson);
    });
  };
  
  render() {
    const { onCancel, visible } = this.props;
    let { SimilarList } = this.state;
    return (
      <ModalComponent
        className="personnel-simila-modal"
        visible={visible}
        onOk={this.onOk}
        onCancel={() => onCancel && onCancel()}
        width="860px"
        title="相似人员推荐">
        <div className="simila-content">
          {!!SimilarList.length ? SimilarList.map(item => {
            return (
              <Similar
                onClick={this.onClick}
                data={item}
                imgUrl={item.newestPictureUrl || item.lastestPictureUrl}
              />
            );
          }) : <NoData />}
          <div className="null-card" />
          <div className="null-card" />
          <div className="null-card" />
          <div className="null-card" />
        </div>
      </ModalComponent>
    );
  }
}

export default SimilarModal;
